import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import PageHeading from '../../components/pageheading';
import Product from '../../components/product';
import Customers from '../../components/customers';
import CallToAction from '../../components/calltoaction';
import Testimonials from '../../components/testimonials';
import stefan from '../../images/persons/StefanCalrell.jpeg'
import img from '../../images/dabas@2x.png';
import dabasOversikt from '../../images/content/dabas-oversikt.png';
import dabasLeverantor from '../../images/content/dabas-leverantor.png';
import dabasMarknadsforning from '../../images/content/dabas-marknadsforing.jpg';
import dabasUpphandling from '../../images/content/dabas-upphandling.jpg'

const Dabas = props => {
    return (
        <Layout>
            <Helmet>
                <title>Delfi.se - Produkt- och artikeldatabas</title>
            </Helmet>
            <article>
                <PageHeading heading="Produkt- och artikeldatabas" 
                    preamble="Dabas är Sveriges största öppna produkt- och artikeldatabas för livsmedel och foodservice. I plattformen Dabas hittar du alltid kvalitetssäkrad produktinformation." 
                    img={img} />
                <Product modifiers={{
                        'product--gray': true
                    }} 
                    heading="Öppen data"
                    img={dabasOversikt}
                    callToAction="Boka ett demomöte"
                    textLeft={true}>
                    <p>
                        Målsättningen med Dabas är att effektivisera informationsflödena i dagligvarubranschen. Det gör vi genom att i en öppen artikeldatabas samla, kvalitetssäkra och tillgängliggöra relevant artikelinformation och bilder om de livsmedel som finns på den svenska marknaden.
                    </p>
                    <Link to="/demo" className="button button--large">Boka ett demomöte</Link>
                </Product>

                <Product heading="Dabas för leverantörer"
                         img={dabasLeverantor}>
                    <p>
                        I Dabas kan du hantera och publicera din artikelinformation och produktdata på ett enda ställe och nå ut till branschen och dina kunder. Skicka varucertifikat till alla dina kunder inom både Livs och Foodservice.
                    </p>
                    <Link to="/demo" className="button button--large">Boka ett demomöte</Link>
                </Product>

                <Product modifiers={{
                        'product--gray': true
                    }} 
                    heading="Dabas för marknadsföring"
                    img={dabasMarknadsforning}
                    callToAction="Boka ett demomöte"
                    textLeft={true}>
                    <p>
                        Med Dabas som plattform kan ditt marknadsföringsteam nå ut med produkt- och marknadsinformation till hela livsmedels- och foodservicebranschen.
                    </p>
                    <Link to="/demo" className="button button--large">Boka ett demomöte</Link>
                </Product>

                <Product heading="Dabas för upphandling"
                         img={dabasUpphandling}>
                    <p>
                        Offentlig sektor upphandlar livsmedel för över 12 miljarder kronor per år. Läs mer om hur Dabas kan hjälpa både dig som upphandlare och leverantör.
                    </p>
                    <Link to="/demo" className="button button--large">Boka ett demomöte</Link>
                </Product>


                <Testimonials statsNumber="41 522"
                              statsText="produkter i Dabas. Öppet och sökbart för dig redan idag."
                              quote="Med Dabas får vi på Martin & Servera ett viktigt stöd när det gäller att säkerställa kvaliteten i våra varucertifikat och tillhandahålla relevant artikelinformation i vår E-handel. Den transparens som Dabas erbjuder när det gäller artikelinformation tror vi också säkerställer kvalitet och likabehandling och effektiviserar upphandlingsprocessen."
                              quoteAuthor="Stefan Calrell" 
                              quoteAuthorTitle="Försäljningschef offentliga kunder Martin & Servera" 
                              quoteAuthorImg={stefan} />


                <CallToAction heading="Vill du veta mer om Dabas?">
                    <Link to="/demo" className="button button--large">Boka ett demomöte</Link>
                </CallToAction>

                <Customers heading="Vi har levererat information till branschen sedan 1987" />
            </article>
        </Layout>
    );
};

export default Dabas;
